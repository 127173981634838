<template>
    <div class="content">
        <!-- 表格内容 -->
        <commonTitle></commonTitle>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="楼幢号" prop="towerNum">
                <el-input v-model="ruleForm.towerNum" placeholder="请输入楼幢号"></el-input>
            </el-form-item>
            <el-form-item label="楼幢类型" prop="type">
                <el-col :span="24">
                    <el-select v-model="ruleForm.type" placeholder="请选择楼幢类型" style="width: 100%" filterable
                               @change="selectOne">
                        <el-option
                                v-for="item in towerTypeList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="是否有电梯" prop="iselevator">
                <el-col :span="24">
                    <el-select v-model="ruleForm.iselevator" placeholder="请选择电梯类型" style="width: 100%" filterable>
                        <el-option
                                v-for="item in dtList"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-col>
            </el-form-item>
            <el-form-item label="建筑面积" prop="coveredarea">
                <el-input v-model.number="ruleForm.coveredarea" autocomplete="off" placeholder="请输入楼幢建筑面积"></el-input>
            </el-form-item>
            <el-form-item label="楼层数" prop="floorNum">
                <el-input type="text" v-model.number="ruleForm.floorNum" placeholder="请输入楼层数"></el-input>
            </el-form-item>
            <el-form-item label="单元数" prop="unitNum">
                <el-input type="text" v-model.number="ruleForm.unitNum" placeholder="请输入单元数"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click="submitForm('ruleForm')">立即修改</el-button>
                <el-button @click="back" size="small">返回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";
    export default {
        name: "upTower",
        components:{
            commonTitle
        },
        data() {
            return {
                currentTime: new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' +
                    new Date().getHours() + ":" + new Date().getMinutes(), // 获取当前时间
                ruleForm: {
                    towerNum: '',  //楼幢号
                    villageId: '',   //小区Id
                    type: '', //楼幢类型
                    iselevator: '',   //是否有电梯
                    coveredarea: '',  //建筑面积
                    unitNum: '', //单元数
                    paystandardId: '',//缴费标准
                    floorNum: ''//楼层数
                },
                rules: {
                    towerNum: [
                        {required: true, message: '请输入楼幢号', trigger: 'change'},
                    ],
                    type: [
                        {required: true, message: '请选择楼幢类型', trigger: 'change'}
                    ],
                    iselevator: [
                        {required: true, message: '请选择是否有电梯', trigger: 'change'}
                    ],
                    coveredarea: [
                        {required: true, message: '请输入建筑面积', trigger: 'change'},
                        {type: 'number', message: '建筑面积必须为数字值'}
                    ],
                    unitNum: [
                        {required: true, message: '请输入单元数', trigger: 'change'},
                        {type: 'number', message: '单元数必须为数字值'}
                    ],
                    floorNum: [
                        {required: true, message: '请输入楼层数', trigger: 'blur'},
                        {type: 'number', message: '楼层数必须为数字值'}
                    ],
                    paystandardId: [
                        {required: true, message: '请选择缴费标准', trigger: 'change'},
                    ]
                },
                towerTypeList: [],  //楼幢类型
                dtList: [],        //电梯类型
                payList: [],          //缴费标准
                url: this.$Config.base_server
            };
        }, methods: {
            selectOne(event, item) {
            }
            , submitForm(formName) {
                var _this = this;
                //修改按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        //获取该小区下面的楼幢信息
                        //向后台提交数据
                        _this.ruleForm.villageId = JSON.parse(localStorage.getItem("villageData")).villageId;
                        $.ajax({
                            url: _this.url+"/api-public/opetower/update?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    //修改成功
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {
                                            window.history.back(-1);
                                        }
                                    });
                                } else {
                                    //修改失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, back() {
                //返回按钮
                window.history.back(-1);
            }, changePwd() {
                //修改密码
                window.location.href = "changepwd.html";
            }
        }, created() {
            //初始化数据
            var _this = this; //声明一个变量指向Vue实例this，保证作用域一致
            //获取上一个页面传送过来的值
            var tolist = this.$route.params.towerData;
            //获取楼幢类型信息
            $.get(this.url+"/api-public/sysdict/list?key=tower_type&access_token=" + localStorage.getItem("token"), function (result) {
                _this.towerTypeList = result;
            });
            //获取电梯类型信息
            $.get(this.url+"/api-public/sysdict/list?key=iselevator&access_token=" + localStorage.getItem("token"), function (result) {
                _this.dtList = result;
            });
            //获取缴费标准类型信息
            $.get(this.url+"/api-public/sysdict/list?key=pay_style&access_token=" + localStorage.getItem("token"), function (result) {
                _this.payList = result;
            });
            _this.ruleForm.type = tolist.type;
            _this.ruleForm.iselevator = tolist.iselevator;
            _this.ruleForm.paystandardId = tolist.paystandardId;
            _this.ruleForm = tolist;
            _this.ruleForm.coveredarea = parseFloat(tolist.coveredarea);
        }
    }
</script>

<style scoped lang="scss">
    .content{
        padding: 20px 111px;
        background: #fff;
    }
    .el-form{
        width: 600px;
        margin: 0 auto;
    }
</style>